<template>
  <div>
    <el-card
      body-style="position: relative"
      shadow="0"
    >
      <div class="device__author">
        {{getDeviceTypeHumanName(device)}} ({{device.author}})
      </div>
      <div class="row align-items-center">
        <div class="col-4 col-lg-3 col-md-3 col-sm-4 p-0">
          <button
            class="device__btn"
            :class="{
              danger_boxshadow: wlk === '1' || mtn === '1'
            }"
            :disabled="!onOff"
            @click.stop="toggleOnOff"
          >
            <!--
              <font-awesome-icon
                icon="lightbulb" size="2x"
              />
              :class="{main_blue_color: onOff === 'on'}"
            -->
<!--            <SensorIcon
              v-if="device.possibilities"
              :possibilites="device.possibilities"
            />-->
            <span
              :class="getDeviceIcon(device)"
              style="font-size: 32px"
            ></span>

          </button>
        </div>
        <div class="col-8 col-lg-9 col-md-9 col-sm-8 text-left text-sm-left text-md-center">
          {{device.name}}
          <!--
            <a v-if="onOff">
              {{onOff === 'on' ? '(включен)' : '(выключен)'}}
            </a>
          -->
          <div v-if="wlk" style="color: #409EFF">
            {{wlk == '1' ? 'протечка!' : 'протечки нет'}}
          </div>
          <div v-if="tmp">
            температура: {{tmp}}&#8451;
          </div>
          <div v-if="hum">
            влажность: {{hum}}%
          </div>
          <div v-if="mtn">
            {{mtn == '1' ? 'есть движение!' : 'движения нет'}}
          </div>
          <div v-if="prs">
            давление: {{prs}}
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getDeviceIcon, getDeviceTypeHumanName } from '@/utils/helpers';

export default {
  /**
   * Компонент для отображения устройства типа sensor
   */
  name: 'Sensor',
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  computed: {
    onOff() {
      /**
       * Свойство вкл/выкл
       */
      if (this.device && this.device.possibilities) {
        return this.device.possibilities['on-off'];
      }
      return false;
    },
    wlk() {
      /**
       * Свойство наличия протечки (0 - нет, 1 - есть)
       */
      if (this.device && this.device.possibilities) {
        return this.device.possibilities.wlk;
      }
      return false;
    },
    tmp() {
      /**
       * Свойство температуры в градусах цельсия
       */
      if (this.device && this.device.possibilities) {
        return this.device.possibilities.tmp;
      }
      return false;
    },
    hum() {
      /**
       * Свойство влажности в процентах
       */
      if (this.device && this.device.possibilities) {
        return this.device.possibilities.hum;
      }
      return false;
    },
    mtn() {
      /**
       * Свойство наличия движения (0 - нет, 1 - есть)
       */
      if (this.device && this.device.possibilities) {
        return this.device.possibilities.mtn;
      }
      return false;
    },
    prs() {
      /**
       * Свойство давления (? ед измерения неизвестны)
       */
      if (this.device && this.device.possibilities) {
        return this.device.possibilities.prs;
      }
      return false;
    },
  },
  methods: {
    getDeviceIcon,
    getDeviceTypeHumanName,
    toggleOnOff() {
      /**
       * Функция обработки изменения свойства вкл/выкл
       */
      if (this.device && this.device.id && this.device.possibilities['on-off']) {
        this.$store.dispatch('changePossibilityValue', {
          deviceId: this.device.id,
          possibilityKey: 'on-off',
          newValue: this.device.possibilities['on-off'] === 'on' ? 'off' : 'on',
        });
      }
    },
  },
};
</script>

<style scoped>
.el-card {
  background: #3c4b73;
  border: solid 1px #4d5878;
  border-radius: 10px;
  color: white;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.danger_boxshadow {
  box-shadow: 6px 6px 10px -1px rgba(255 ,0, 0,0.2),  -6px -6px 10px -1px rgba(255,0,0,0.4);
}
</style>
