// eslint-disable-next-line import/prefer-default-export
export function getDeviceIcon(device) {
  if (device && device.possibilities) {
    const possibilites = Object.keys(device.possibilities).sort();
    while (possibilites.length > 0) {
      switch (possibilites[0]) {
        case 'wlk':
          return 'icon-water';
        case 'tmp':
          return 'icon-temp';
        case 'hum':
          return 'icon-humidity';
        case 'mtn':
          return 'icon-motion';
        case 'prs':
          return 'icon-pressure';
        default:
          possibilites.shift();
      }
    }
  }
  return 'icon-any';
}
export function getDeviceTypeHumanName(device) {
  if (device && device.possibilities) {
    const possibilitiesWithDeviceNames = ['wlk', 'tmp', 'hum', 'mtn', 'prs'];
    const possibilites = Object.keys(device.possibilities).sort();
    const intersection = possibilitiesWithDeviceNames.filter((el) => possibilites.includes(el));
    if (intersection.length === 1) {
      switch (intersection[0]) {
        case 'wlk':
          return 'Датчик протечки';
        case 'tmp':
          return 'Датчик температуры';
        case 'hum':
          return 'Датчик влажности';
        case 'mtn':
          return 'Датчик движения';
        case 'prs':
          return 'Датчик давления';
        default:
          break;
      }
    }
  }
  return 'Устройство';
}
